import * as React from "react"

import ToolScreen from '../../../../components/toolScreen'


const Tool = ({ data }) => (
  <ToolScreen
    name="Protopie"
    img_name="protopie_logo.png"
    link="https://www.protopie.io/"
    description="Protopie offers advanced features for professional designers looking for a solution to prototype complex interactions and animations."
    twitterHandleTool="@ProtoPieApp"
  />
)

export default Tool;


